import React, { useState } from "react"

import BackendClient, { IDashboardData } from "../../lib/BackendClient"


import { XAxis, YAxis,ResponsiveContainer, Bar, BarChart, Tooltip, CartesianGrid } from 'recharts';
import { Card, Typography } from "@mui/material";


const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Monate sind 0-basiert
    return `${day}.${month}.`;
};

const ActivityChart = () => {
    const [initialized, setInitialized] = useState<boolean>(false)
    const [data, setData] = useState<IDashboardData>()

    React.useEffect(() => {
        const fetch = async () => {
            const result = await BackendClient.instance.stats()
            setData(result)
            setInitialized(true)
        }

        if(!initialized) fetch()
    })

    return (
        <>
        <Card sx={{padding: 3, marginBottom: 3}}>
                <Typography variant="h6" gutterBottom>
                    Users
                </Typography>
                <Typography variant="h2" gutterBottom>
                    {data ? data.totalUsers : '...'}
                </Typography>


            </Card>

        <Card sx={{padding: 3, marginBottom: 3}}>
            <Typography variant="h5" gutterBottom>
                Interactions within the last month
            </Typography>
            <Typography variant="h6" gutterBottom>
                {data ? data.activeUsers : '...' } active users
                {data && data.totalUsers > 0 && <>&nbsp;
                ({Math.round(100 * 100 / data.totalUsers * data.activeUsers) / 100}%)
                </>}
            </Typography>

            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data?.usage}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tickFormatter={formatDate} />
                    <YAxis />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="interactions" fill="#298044" />
                </BarChart>
            </ResponsiveContainer>
        </Card>
        </>
    )
}

export default ActivityChart
