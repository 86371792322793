import React, { useState } from "react"

import BackendClient, { IDashboardData } from "../../lib/BackendClient"


import { XAxis, YAxis,ResponsiveContainer, Bar, BarChart, Tooltip, CartesianGrid } from 'recharts';
import { Card, Typography } from "@mui/material";


const EventsChart = () => {
    const [initialized, setInitialized] = useState<boolean>(false)
    const [data, setData] = useState<IDashboardData[] | null>(null)

    React.useEffect(() => {
        const fetch = async () => {
            const result = await BackendClient.instance.eventCategories()
            setData(result)
            setInitialized(true)
        }

        if(!initialized) fetch()
    })

    return (
        <Card sx={{padding: 3, marginBottom: 3}}>
            <Typography variant="h5" gutterBottom>
                By category past 30 days
            </Typography>



{data &&
            <ResponsiveContainer width="100%" height={800}>
            <BarChart
              data={data}
              layout="vertical" // Make the bars horizontal
              margin={{ top: 20, right: 30, left: 100, bottom: 20 }} // Adjust margin for horizontal layout
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" /> {/* X-axis now shows the values */}
              <YAxis
                dataKey="category"
                type="category"
                width={150} // Adjust width for long category labels
              /> {/* Y-axis now shows the categories */}
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
}
        </Card>
    )
}

export default EventsChart
